import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import Signature from "../../../hooks/userSign";
import { useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
export default function GetReffLink({
  getRefLink,
  setBool,
  bool,
  setWalletBool,
}) {
  const [inputText, setInputText] = useState("");
  const [show1, setShow1] = useState(false);
  const { account } = useWeb3React();
  const handleClose1 = () => setShow1(false);
  const [refLink, setRefLink]=useState(null)
  const handleShow1 = () => setShow1(true);
  const { userSign } = Signature();
  const [availabilityChecked, setAvailabilityChecked] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // Track dropdown state
  const [available, setavailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [referrar, setReferrar] = useState();
  const [copied, setCopied] = useState(false);
  const [walletError, setWalletError] = useState("");
  const history = useHistory();
  const handleInputChange = (e) => {
    setAvailabilityChecked(false);

    setavailable(false);
    setInputText(e.target.value);
  };

  const handleClearClick = () => {
    if (!available) {
      setAvailabilityChecked(false);
      setavailable(false);
      setInputText("");
    }
  };

  const [show, setShow] = useState(getRefLink);
  const handleClose = () => {
    setShow(false);
    setWalletError("");
  };
  const handleShow = () => setShow(true);
  function checkNameAvail() {
    setIsLoading(true);

    axios
      .get(Envirnoment.apiUrl + "users/get-refferer-user", {
        params: {
          referralBy: inputText,
        },
      })
      .then((response) => {
        // Handle the API response here
        console.log(response.data);
        setAvailabilityChecked(true);
        setavailable(true);
        // You can perform additional actions based on the API response
      })
      .catch((error) => {
        setAvailabilityChecked(true);
        setavailable(false);
        console.error("Error checking username availability:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  async function setNameFunc() {
    if (inputText && !available) {
      toast.error("Enter Correct Referrar Name or Wallet Address");
      return;
    }
    const sign = await userSign(account?.toString());
    setIsConfirmLoading(true);
    let data;
    if (inputText && available) {
      data = {
        walletAddress: account,
        sign,
        referralBy: inputText,
      };
    } else if (referrar) {
      data = {
        walletAddress: account,
        sign,
        referralBy: referrar,
      };
    } else {
      data = {
        walletAddress: account,
        sign,
      };
    }

    axios
      .post(Envirnoment.apiUrl + "auth/signup", data)
      .then((response) => {
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        localStorage.setItem(
          "refreshToken",
          response?.data?.data?.refreshToken
        );
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
        getRefLink || setBool(!bool);
        console.log(response.data);
        toast.success("User Signup successfully");
        handleClose();
        handleShow1();
        // You can perform additional actions based on the API response
      })
      .catch((error) => {
        // Handle API errors here
        toast.error(error.request?.statusText);
        console.error("Error checking username availability:", error);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }

  function copyFunc(params) {
    copy(params);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }
  // useEffect(() => {
  //      let refrerr = window.location.search.split('=')[1]
  //      console.log(refrerr);
  // setReferrar(refrerr)
  // }, [])

  useEffect(() => {
    // Function to get cookie expiry time
    function getExpiryTime() {
      const currentTime = new Date().getTime();
      const expiryTime = currentTime + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      return expiryTime;
    }

    // Function to check if cookie is expired
    function isCookieExpired(cookieName) {
      const cookieValue = localStorage.getItem(cookieName);
      if (!cookieValue) {
        return true; // Cookie doesn't exist
      }
      const expiryTime = parseInt(cookieValue.split("_")[1]);
      console.log(expiryTime);

      const currentTime = new Date().getTime();
      return currentTime > expiryTime;
    }

    // Check if the referral cookie is expired
    const isReferralCookieExpired = isCookieExpired("referrer_expiry");

    // Extract referral from window.location
    const queryParams = new URLSearchParams(window.location.search);
    const referralParam = queryParams.get("ref");

    // If there's a new referral or the cookie is expired, update it
    if (referralParam) {
      const currentReferrer = localStorage.getItem("referrer");

      // Update referrer if it's a new one or if the cookie is expired
      if (isReferralCookieExpired || referralParam !== currentReferrer) {
        localStorage.setItem("referrer", referralParam);
        localStorage.setItem("referrer_expiry", `${getExpiryTime()}`);
        // setReferrar(referralParam); // Update your state or whatever is needed here
      }
    } else if (!isReferralCookieExpired) {
      // Retrieve referral from cookie if no new referral is provided
      const savedReferrer = localStorage.getItem("referrer");
      // setReferrar(savedReferrer);
    }
  }, []);
  let user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if(account){
      setRefLink({ name: 'Home', link: `https://hydtprotocol.com/?ref=${account}` })
    }
  }, [account])
  
  return (
    <>
      {user?.walletAddress ? null : (
        <button onClick={handleShow} className="get-btn">
          Get Your Referral Link
        </button>
      )}
      <Modal
        className="request-withdraw-modal"
        show={show}
        onHide={() => (getRefLink ? null : handleClose())}
        centered
      >
        <Modal.Header closeButton={getRefLink ? false : true}>
          <Modal.Title>First Time User Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="firsttimeuser">
            <h6>
              You have not used HYDT staking before, so your wallet address is
              not in protocol database yet.
            </h6>
            <h6>
              Please confirm your referrer, and input your referrer manually if
              required.
            </h6>
            <div className="option-field">
              <label>Your Referrer</label>
              <div className="twice-field">
                {/* <div class="dropdown"> */}
                {/* <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                      Username <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                          <path d="M8.99965 12.6C8.47465 12.6 7.94965 12.3975 7.55215 12L2.66215 7.11C2.44465 6.8925 2.44465 6.5325 2.66215 6.315C2.87965 6.0975 3.23965 6.0975 3.45715 6.315L8.34715 11.205C8.70715 11.565 9.29215 11.565 9.65215 11.205L14.5421 6.315C14.7596 6.0975 15.1196 6.0975 15.3371 6.315C15.5546 6.5325 15.5546 6.8925 15.3371 7.11L10.4471 12C10.0496 12.3975 9.52465 12.6 8.99965 12.6Z" fill="white" />
                                      </svg>
                                  </button> */}
                {/* <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Username</a></li>
                                      <li><a class="dropdown-item" href="#">Wallet Address</a></li>

                                  </ul> */}
                {/* </div> */}
                <input
                  disabled
                  className="w-100"
                  type=""
                  value={referrar || "No Referrer Found"}
                  placeholder="Search..."
                />
              </div>
            </div>
            <div className="option-field option-field1">
              <label>Input referrer’s username or wallet address</label>
              <input
                value={inputText}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Your Username "
                className={
                  available && availabilityChecked
                    ? "has-text-success"
                    : !available && availabilityChecked
                      ? "has-text"
                      : ""
                }
              />
              {isLoading ? (
                <button className="btn-checkk loading">Check</button>
              ) : (
                <button
                  disabled={!inputText}
                  className={inputText ? "btn-checkk" : "btn-checkk disable"}
                  onClick={checkNameAvail}
                >
                  Check
                </button>
              )}

              {inputText && (
                <a onClick={handleClearClick}>
                  {!available && availabilityChecked ? (
                    <img
                      src={"/assests/close-red.svg"}
                      alt="img"
                      className="img-fluid close-red"
                      style={{ top: "64%" }}
                    />
                  ) : available && availabilityChecked ? (
                    <img
                      src={"/assests/tick.svg"}
                      alt="img"
                      className="img-fluid close-red"
                      style={{ top: "64%" }}
                    />
                  ) : null}
                </a>
              )}
            </div>
            <div className="text-danger mb-3">{walletError}</div>
            {/* <div className="option-field option-field1">
                          <label>Input referrer’s username or wallet address</label>
                          <input value={inputText}
                              onChange={handleInputChange} type="text" placeholder='Enter Your Username' className={inputText ? "has-text" : ""} />
                          <button className='btn-checkk'>Check</button>
                          {inputText && (
                              <a onClick={handleClearClick}><img src="\assests\close-red.svg" alt="img" className='img-fluid close-red' /></a>
                          )}

                      </div> */}
          </div>
          <div className="bottom-btns">
            <button
              onClick={() => {
                getRefLink ? history.push("/HYDT/dashboard") : handleClose();
              }}
              className="btn-cancel"
            >
              Cancel
            </button>
            {/* <button className='btn-withdraw' onClick={() => {
                          handleClose();
                          handleShow1();
                      }}>Confirm</button> */}
            <button
              className={available ? "btn-withdraw" : "btn-withdraw"}
              onClick={() => {
                if (account) {
                  setNameFunc();
                } else {
                  setWalletError("Connect Your Wallet First!");
                  setWalletBool(true);
                }
                // account ? setNameFunc() : setWalletBool(true);
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="request-withdraw-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>First Time User Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="firsttimeuser">
            <h6>Your referrer has been confirmed</h6>
            <h6>Here is your unique referral link:</h6>
            <div className="inner-filed " style={{ padding: '0', paddingRight: '8px' }}>
              <Dropdown onToggle={() => setShowDropdown(!showDropdown)} show={showDropdown} className="appdropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  {refLink?.name}
                  <img src="/assests/Vector.svg" alt="img" className="rotate" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <a href="#" onClick={() => { setShowDropdown(false); setRefLink({ name: 'Home', link: `https://hydtprotocol.com/?ref=${refLink}` }) }} className="dropitem">
                    Home
                  </a>
                  <a href="#" onClick={() => { setShowDropdown(false); setRefLink({ name: 'App', link: `https://app.hydtprotocol.com/HYDT/earn?ref=${refLink}` }) }} className="dropitem">
                    App
                  </a>
                </Dropdown.Menu>
              </Dropdown>
              <h5>{refLink?.link}</h5>
            </div>
          </div>
          <div className="bottom-btns">
            <button onClick={handleClose1} className="btn-cancel">Cancel</button>
            {copied ? (
              <button
                style={{ cursor: "copy" }}
                className="btn-withdraw disable"
              >
                Copied
              </button>
            ) : (
              <button
                style={{ cursor: "copy" }}
                onClick={() =>
                  copyFunc(
                    refLink?.link
                  )
                }
                className="btn-withdraw"
              >
                Copy
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
