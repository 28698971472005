import React, { useState, useEffect } from "react";
import "./earn.scss";
import axios from "axios";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Slider } from "@material-ui/core";
import EarnFee from "../../hooks/dataFetchers/earnFee";
import DailyPayout from "../../hooks/dataFetchers/dailyPayout";
import { useWeb3React } from "@web3-react/core";
import { EarnApy } from "../../hooks/dataFetchers/earnApy";
import { TotalReturns } from "../../hooks/dataFetchers/totalReturns";
import { CheckBalance } from "../../hooks/dataFetchers/balanceOfHydt";
import { EarnApprove } from "../../hooks/dataSenders/earnApprove";
import { HydtAllowance } from "../../hooks/dataFetchers/hydtAllowance";
import { EarnStake } from "../../hooks/dataSenders/earnStake";
import Environment from "../../utils/environment";
import Loader from "../../hooks/loader";
import moment from "moment";
import useWeb3 from "hooks/useWeb3";
import EarnChild from "./EarnChild";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VolumeUp from "@mui/icons-material/VolumeUp";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import { controlResolverAbi } from "utils/contractHelpers";
import { CListGroup } from "@coreui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EarnHygtApy } from "../../hooks/dataFetchers/hygtApyEarn";
import GetReffLink from "views/Affliate/AffiliateSubComp/GetReffLink";

const Earn = ({ walletBool, setWalletBool }) => {
  const [addCls, setcls] = useState(false);
  // console.log(addCls, "addCls");
  const { depositAmount } = EarnFee();
  const { payoutDaily } = DailyPayout();
  const { apyAmount } = EarnApy();
  const { returnHydt } = TotalReturns();
  const { balanceOfHydt } = CheckBalance();
  const { AllowanceHydt } = HydtAllowance();
  const { approveEarn } = EarnApprove();
  const { stakeEarn } = EarnStake();
  const { hygtapyAmount } = EarnHygtApy();
  const web3 = useWeb3();
  const history = useHistory();
  const { account } = useWeb3React();
  const [update, setupdate] = useState(0);
  const [depositFee, setDepositFee] = useState("");
  const [payoutPer, setPayoutPer] = useState("");
  const [apy, setApy] = useState("");
  const [balanceOf, setBalanceOf] = useState("");
  const [totalReturns, setTotalReturns] = useState("");
  const [approv, setApprov] = useState("");
  const [allowance, setAllowance] = useState("");
  const [hydtAmount, setHydtAmount] = useState("");
  const [hydtBal, setHydtBal] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [activeDeposit, setActiveDeposit] = useState("");
  const [rend, setRend] = useState(false);
  const [hygtApyArr, setHygtApyArr] = useState([0]);
  let user = JSON.parse(localStorage.getItem("user"));
  // console.log('hydtAmount', hydtAmount)
  const DepositFee = async () => {
    let result = await depositAmount();
    setDepositFee(result);
  };
  const Payouts = async () => {
    let result = await payoutDaily(update);
    setPayoutPer(result);
  };
  const ApyFun = async () => {
    let result = await apyAmount(update);
    // console.log('depositAmount', result)
    setApy(result);
  };
  // console.log('depositAmount update', update)
  // const ReturnsBal = async (update) => {
  //   let result = await returnHydt(update);
  //   setTotalReturns(result);
  // };

  const getBalance = async () => {
    let result = await balanceOfHydt(account);
    setBalanceOf(result);
    setMainLoader(false);
  };
  const ApproveFun = async () => {
   
    
    if (parseFloat(hydtAmount) < 200) {
      toast.info("You can only stake 200 HYDT or more", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (!account) {
      toast.info("Connect Your Wallet", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (parseFloat(balanceOf) === 0) {
      toast.info("You don't have enough balance", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (parseFloat(hydtAmount) === 0) {
      toast.info("Enter HYDT Amount", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    try {
      setMainLoader(true);
      let result = await approveEarn();
      setApprov(result);
      HydtAllow();
      toast.success("Approved Successfully", {
        position: "top-right",
        autoClose: 2000,
      });
    } catch (error) {
      let a = error.message.slice(29, 47);
      toast.error(
        a?.includes("transaction") ? "User Denied Transaction" : error,
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
      setMainLoader(false);
    }
  };
  // console.log("updateupdate", update, hydtAmount);
  const StakeFun = async (update, hydtAmount) => {
    if (parseFloat(hydtAmount) < 200) {
      toast.info("You can only stake 200 HYDT or more", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    try {
      if (
        parseFloat(hydtAmount) <= parseFloat(balanceOf) &&
        parseFloat(hydtAmount) > 0
      ) {
        setMainLoader(true);
        let res = 0;
        // if (hydtAmount.length > 19 ){
        //   res = hydtAmount;
        // }else{
        //   res =hydtAmount
        // }
        // console.log("hydtAmounthydtAmount", hydtAmount, "lenght", hydtAmount.length,);
        // console.log("upppppppppp", parseFloat(hydtAmount).toFixed(18));
        let result = await stakeEarn(hydtAmount, update);
        if (result) {
          setTimeout(() => {
            getBalance();
            getDepositData();
          }, 3000);
        }
        setHydtAmount("");
        let result1 = await AllowanceHydt();
        if (result1) {
          setAllowance(result1);
        }
        setMainLoader(false);
        toast.success("Staked Successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        toast.error("Enter correct HYDT Amount", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch {
      setMainLoader(false);
    }
    // setApprov(result);
  };
  const [status, setStatus] = useState(false);
  const HydtAllow = async () => {
    try {
      let result = await AllowanceHydt();
      setAllowance(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const HydtAllow111 = async () => {
    try {
      let balanceOfUpdated = await balanceOfHydt(account);
      let result = await AllowanceHydt();
      if (balanceOfUpdated) {
        if (result < balanceOfUpdated) {
          // console.log("AllowanceHydtAllowanceHydt", result, "sxsc", balanceOfUpdated);
          setAllowance(0);
        } else {
          setAllowance(result);
        }
      }
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const GetHYDT = () => {
    history.push("/HYDT/initialmint");
  };
  // console.log("statusstatus", status);
  useEffect(() => {
    if (account) {
      // console.log("balance and allowance");
      DepositFee();
      getBalance();
      HydtAllow();
    }
  }, [account]);
  useEffect(() => {
    // console.log('first account', account);
    ApyFun(update);
    Payouts();
  }, [update]);

  const handleHydt = (e) => {
    // console.log("33333333", e.target.value);
    setHydtAmount(e.target.value);
    if (balanceOf && e.target.value <= balanceOf && e.target.value > 0) {
      setValue((e.target.value / balanceOf) * 100);
    }

    if (e.target.value > 0) {
      let dailyPayouts = [1.156, 0.611, 0.356];
      let lockPeriods = [90, 180, 365];
      let y =
        ((0.985 * e.target.value * dailyPayouts[update]) / 100) *
        lockPeriods[update];
      // console.log("totalReturnstotalReturns", y)
      setTotalReturns(y);
    }
  };
  // console.log("approv", totalReturns);
  //Graph Apis
  const getDepositData = () => {
    setMainLoader(true);
    let acc = JSON.stringify(account);
    var data = JSON.stringify({
      query: `query 
      MyQuery {stakes(
    where: {user: ${acc}, status: true}
    orderBy: startTime
    orderDirection: desc
  ) {
    user
    index
    status
    startTime
    lockPeriod
    amount
    APY
    endTime
  }}`,
    });
    var config = {
      method: "post",
      url: Environment.hydtGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setActiveDeposit(response?.data?.data?.stakes);
        // console.log("minted", response);
        setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };
  useEffect(() => {
    if (account) {
      getDepositData();
    }
  }, [account]);

  //range slider
  const [value, setValue] = React.useState(0);
  const handleSliderChange = (event, newValue) => {
    // console.log("dsscdcdsc3333", newValue);
    setValue(newValue);
    setHydtAmount((newValue * balanceOf) / 100);

    let dailyPayouts = [1.156, 0.611, 0.356];
    let lockPeriods = [90, 180, 365];
    let y =
      ((((0.985 * newValue * balanceOf) / 100) * dailyPayouts[update]) / 100) *
      lockPeriods[update];
    // console.log("totalReturnstotalReturns", y)
    setTotalReturns(y);
  };

  const handleInputChange = (event) => {
    // console.log("11111");
    // console.log("balance1111111111", (event.target.value * balanceOf) / 100);
    setHydtAmount((event.target.value * balanceOf) / 100);
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    // console.log("22222");
    // console.log("balanceOfbalanceOf", value);
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };
  const Input = styled(MuiInput)`
    width: 42px;
  `;
  const handleMaxBal = () => {
    setHydtAmount(balanceOf);
    if (balanceOf > 0) {
      setValue(balanceOf && (balanceOf / balanceOf) * 100);
    }
    let dailyPayouts = [1.156, 0.611, 0.356];
    let lockPeriods = [90, 180, 365];
    let y =
      ((0.985 * balanceOf * dailyPayouts[update]) / 100) * lockPeriods[update];
    // console.log("totalReturnstotalReturns", y)
    setTotalReturns(y);
    // setRend(!rend);
  };
  const earnApyFunc = async () => {
    try {
      setMainLoader(true);
      let res = await hygtapyAmount();
      setHygtApyArr(res);
      // setHygtApyArr(['16', '20', '30'])
      setMainLoader(false);
    } catch (error) {
      // console.log(error, 'Error')
      // setHygtApyArr([null, null, null])
      setMainLoader(false);
    }
  };
  useEffect(() => {
    earnApyFunc();
  }, []);
  useEffect(() => {
    // Function to get cookie expiry time
    function getExpiryTime() {
      const currentTime = new Date().getTime();
      const expiryTime = currentTime + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      return expiryTime;
    }

    // Function to check if cookie is expired
    function isCookieExpired(cookieName) {
      const cookieValue = localStorage.getItem(cookieName);
      if (!cookieValue) {
        return true; // Cookie doesn't exist
      }
      const expiryTime = parseInt(cookieValue.split("_")[1]);
      console.log(expiryTime);

      const currentTime = new Date().getTime();
      return currentTime > expiryTime;
    }

    // Check if the referral cookie is expired
    const isReferralCookieExpired = isCookieExpired("referrer_expiry");

    // Extract referral from window.location
    const queryParams = new URLSearchParams(window.location.search);
    const referralParam = queryParams.get("ref");

    // If there's a new referral or the cookie is expired, update it
    if (referralParam) {
      const currentReferrer = localStorage.getItem("referrer");

      // Update referrer if it's a new one or if the cookie is expired
      if (isReferralCookieExpired || referralParam !== currentReferrer) {
        localStorage.setItem("referrer", referralParam);
        localStorage.setItem("referrer_expiry", `${getExpiryTime()}`);
        // setReferrar(referralParam); // Update your state or whatever is needed here
      }
    } else if (!isReferralCookieExpired) {
      // Retrieve referral from cookie if no new referral is provided
      const savedReferrer = localStorage.getItem("referrer");
      // setReferrar(savedReferrer);
    }
  }, []);

  return (
    <>
      {mainLoader && <Loader />}
      <div className="content">
        <section className="main-earn">
          <div className="main-heading">
            <h4 className="ml-15">Stake HYDT</h4>
          </div>
          <div className="row">
            <div className="col-xl-4 col-12 padd-sm">
              <div className="left-content">
                <div className="upper-head">
                  <h6>Stake</h6>
                </div>
                <ButtonGroup className="time-select" aria-label="Basic example">
                  <Button
                    className={update == 0 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(0);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    3 Months
                  </Button>
                  <Button
                    className={update == 1 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(1);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    6 Months
                  </Button>
                  <Button
                    className={update == 2 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(2);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    12 Months
                  </Button>
                </ButtonGroup>
                <div className="option-field">
                  <div className="upper-area">
                    <label>Stake</label>
                    <h6>
                      {balanceOf ? parseFloat(balanceOf).toFixed(5) : 0}
                      <span>:Balance</span>
                    </h6>
                  </div>
                  <div className="input-field">
                    <input
                      type="number"
                      placeholder="Enter HYDT amount"
                      onChange={handleHydt}
                      value={hydtAmount}
                    />
                    <button onClick={handleMaxBal}>MAX</button>
                  </div>
                </div>
                <div className="d-flex  justify-content-center">
                  <h5 onClick={GetHYDT} className="sytvcstscdfd">Get HYDT</h5>
                </div>
                <div className="slide-ranger">
                 
                  {/* <input type="range" min="1" max="100" class="slider" id="myRange" onChange={(e)=>moveSlider(e)}/> */}
                  {/* <Slider
                    defaultValue={5}
                    onChangeCommitted={(e) => moveSlider(e)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  /> */}
                  <Box sx={{ width: 250 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={typeof value === "number" ? value : 0}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                          valueLabelDisplay="auto"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={value}
                          size="small"
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                        // inputProps={{
                        //   step: 10,
                        //   min: 0,
                        //   max: 100,
                        //   type: "number",
                        //   "aria-labelledby": "input-slider",
                        // }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <div className="inner-value">
                    <span>0%</span>
                    <span>100%</span>
                  </div> */}
                </div>
                <div className="bottom-text">
                  <div className="text">
                  <div className="maintext">
                      <h6>Fixed HYDT Return</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                      Fixed HYDT Return: Total HYDT payouts including both principal and yield. Amount is fixed.
                      </div>
                    </div>
                    <h5>
                      {totalReturns ? parseFloat(totalReturns).toFixed(5) : 0}{" "}
                      <span>
                        HYDT{" "}
                        <img
                          src="\assests\buttonsvgs\hydt.svg"
                          alt="img"
                          className="img-fluid ml-2x"
                        />
                      </span>
                    </h5>
                  </div>
                  <div className="text">
                  <div className="maintext">
                      <h6>APY</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                      APY: Expected interest rate earned on staking in one year. HYGT rate is not guaranteed and subject to change. 
                      </div>
                    </div>
                    {/* <h5>{apy}%</h5> jawaaaaad */}
                    <h5>
                      <img
                        src="\assests\hydt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {apy}% +{" "}
                      <img
                        src="\assests\hygt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {hygtApyArr[update]
                        ? parseFloat(hygtApyArr[update])?.toFixed(1)
                        : 0}
                      %
                    </h5>
                  </div>
                  <div className="text">
                    <div className="maintext">
                      <h6>Daily Payouts</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                        Daily Payouts: Expected daily payout rate. HYGT rate is not guaranteed and subject to change.
                      </div>
                    </div>
                    {/* <h5>{apy}%</h5> jawaaaaad */}
                    {/* <h5>
                      <img
                        src="\assests\hydt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {payoutPer}% +{" "}
                      <img
                        src="\assests\hygt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {update === 0 && hygtApyArr[update] !== undefined && (
                        parseFloat((((hygtApyArr[update] / 4) + 100) / 90).toFixed(3))
                      )}
                      {update === 1 && hygtApyArr[update] !== undefined && (
                        parseFloat((((hygtApyArr[update] / 2) + 100) / 180).toFixed(3))
                      )}
                      {update === 2 && hygtApyArr[update] !== undefined && (
                        parseFloat((((parseFloat(hygtApyArr[update]) + 100) / 365).toFixed(3)))
                        )}
                      %
                    </h5> */}
                    <h5>
                      <img
                        src="\assests\hydt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {payoutPer}% +{" "}
                      <img
                        src="\assests\hygt-small-icon.svg"
                        alt="img"
                        className="img-fluid me-1"
                      />
                      {update === 0 && (
                        hygtApyArr[update] !== undefined
                          ? parseFloat((hygtApyArr[update] / 365).toFixed(4))
                          : 0
                      )}
                      {update === 1 && (
                        hygtApyArr[update] !== undefined
                          ? parseFloat((hygtApyArr[update] / 365).toFixed(4))
                          : 0
                      )}
                      {update === 2 && (
                        hygtApyArr[update] !== undefined
                          ? parseFloat((hygtApyArr[update] / 365).toFixed(4))
      : 0
                      )}
                      %
                    </h5>

                  </div>
                  {/* <div className="text">
                  <div className="maintext">
                      <h6>Daily Payouts</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                      Daily Payouts: Expected daily payout rate. HYGT rate is not guaranteed and subject to change.
                      </div>
                    </div>
                    <h5>
                      {payoutPer}% <span>daily</span>
                    </h5>
                  </div> */}
                  <div className="text">
                  <div className="maintext">
                      <h6>Period</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                      Period: You receive staking principal and yield at the same time during staking period, means there are no principal release at the end of staking period. No early redemption is available.
                      </div>
                    </div>
                    <h5>
                      {update === 0 && 90} {update === 1 && 180}{" "}
                      {update === 2 && 365} <span>Days</span>
                    </h5>
                  </div>
                  <div className="text">
                    <div className="maintext">
                      <h6>Staking Fees</h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="questionimg">
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#313131" />
                        <path d="M11.0483 14.4574V14.3977C11.0549 13.7647 11.1212 13.2609 11.2472 12.8864C11.3731 12.5118 11.5521 12.2086 11.7841 11.9766C12.0161 11.7446 12.2945 11.5308 12.6193 11.3352C12.8149 11.2159 12.9905 11.075 13.1463 10.9126C13.3021 10.7469 13.4247 10.5563 13.5142 10.3409C13.607 10.1255 13.6534 9.88684 13.6534 9.625C13.6534 9.30019 13.5772 9.01847 13.4247 8.77983C13.2723 8.54119 13.0684 8.35724 12.8132 8.22798C12.558 8.09872 12.2746 8.03409 11.9631 8.03409C11.6913 8.03409 11.4295 8.09044 11.1776 8.20312C10.9257 8.31581 10.7152 8.49313 10.5462 8.73509C10.3771 8.97704 10.2794 9.29356 10.2528 9.68466H9C9.02652 9.12121 9.17235 8.63897 9.4375 8.23793C9.70597 7.83688 10.0589 7.5303 10.4964 7.31818C10.9373 7.10606 11.4261 7 11.9631 7C12.5464 7 13.0535 7.116 13.4844 7.34801C13.9186 7.58002 14.2533 7.8982 14.4886 8.30256C14.7273 8.70691 14.8466 9.16761 14.8466 9.68466C14.8466 10.0492 14.7902 10.379 14.6776 10.674C14.5682 10.969 14.4091 11.2325 14.2003 11.4645C13.9948 11.6965 13.7462 11.902 13.4545 12.081C13.1629 12.2633 12.9292 12.4555 12.7536 12.6577C12.5779 12.8565 12.4503 13.0935 12.3707 13.3686C12.2912 13.6437 12.2481 13.9867 12.2415 14.3977V14.4574H11.0483ZM11.6847 17.4006C11.4394 17.4006 11.2289 17.3127 11.0533 17.1371C10.8776 16.9614 10.7898 16.7509 10.7898 16.5057C10.7898 16.2604 10.8776 16.05 11.0533 15.8743C11.2289 15.6986 11.4394 15.6108 11.6847 15.6108C11.9299 15.6108 12.1404 15.6986 12.3161 15.8743C12.4917 16.05 12.5795 16.2604 12.5795 16.5057C12.5795 16.6681 12.5381 16.8172 12.4553 16.9531C12.3757 17.089 12.268 17.1984 12.1321 17.2812C11.9995 17.3608 11.8504 17.4006 11.6847 17.4006Z" fill="#313131" />
                      </svg>
                      <div className="hoverdiv">
                        Staking Fees: 1.5% One-time fee is deducted upon staking. Staking fee generates Protocol revenue, which used for HYGT Governance token buy-back.
                      </div>
                    </div>
                    <h5>
                      {parseFloat(((parseFloat(depositFee) * parseFloat(hydtAmount)) / 100) || 0)?.toFixed(5)} <span>HYDT</span><img
                        src="\assests\buttonsvgs\hydt.svg"
                        alt="img"
                        className="img-fluid ml-2x"
                      />
                    </h5>
                  </div>
                </div>
                {parseFloat(allowance) > parseFloat(hydtAmount) ? (
                  <>
                    <div
                      className="btn-approve"
                      onClick={() => StakeFun(update, hydtAmount)}
                    >
                      <button>Stake</button>
                    </div>
                    <div className="bottom-progress">
                      <span className="activespan unactivespan">1</span>
                      <img
                        src="\assests\buttonsvgs\line.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <span className="activespan ">2</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="btn-approve" onClick={ApproveFun}>
                      <button>Approve</button>
                    </div>
                    <div className="bottom-progress">
                      <span className="activespan">1</span>
                      <img
                        src="\assests\buttonsvgs\line.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <span className="activespan unactivespan">2</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-xl-8 col-12 padd-sm">
              <div className="right-content">
                <div className="upper-head">
                  <h6>Active Staking</h6>
                  {/* <button className="btn-transparent">Claim All</button> */}
                </div>
                <div className="parent-items">
                  {/* {activeDeposit.length > 0 ?
                    activeDeposit?.map((item, index) => { */}
                  {activeDeposit.length > 0 ? (
                    activeDeposit?.map((item, index) => {
                      // console.log("item?.", item?.amount);

                      let date = new Date(item?.startTime * 1000);
                      const dateTime = moment(date).format("H:mm DD-MM-YYYY");
                      let date1 = new Date(item?.endTime * 1000);
                      const endTime = moment(date1).format("H:mm DD-MM-YYYY");
                      const currentTime = new Date().getTime();
                      const distanceToDate = item?.endTime * 1000 - currentTime;
                      // // console.log(
                      //   "distanceToDatedistanceToDate",
                      //   distanceToDate
                      // );

                      let x = currentTime / 1000 - item?.startTime;
                      let y = item?.endTime - item?.startTime;
                      let progress = (x / y) * 100;
                      // // console.log(
                      //   "lllllllllllllllqqqqqqqqq",
                      //   progress, item?.index
                      // );
                      return (
                        <>
                          <EarnChild
                            getDepositData={getDepositData}
                            item={item}
                            dateTime={dateTime}
                            endTime={endTime}
                            distanceToDate={distanceToDate}
                            progress={progress}
                            getBalance={getBalance}
                            HydtAllow111={HydtAllow111}
                            hygtApyArr={hygtApyArr}
                          />
                        </>
                      );
                    })
                  ) : (
                    <p className="deposts mt-2">
                      You currently have no active deposits
                    </p>
                  )}
                </div>
              </div>
              {/* <button className="load-btn">Load More</button> */}
              {/* <div className="pagination-custom">
                <div className="left">
                  <img
                    src="\assests\buttonsvgs\arrow-left.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="inner-values">
                  <div className="spec">1</div> <span>/</span>{" "}
                  <div className="total">10</div>
                </div>
                <div className="right">
                  <img
                    src="\assests\buttonsvgs\arrow-right.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
      {user?.walletAddress ? null : (
        account && 
        <GetReffLink getRefLink={true} setWalletBool={setWalletBool} />
      )}
      {/* {true &&
        <GetReffLink getRefLink={true} setWalletBool={setWalletBool} />
      } */}
    </>
  );
};

export default Earn;
